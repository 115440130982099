import axios from "axios";
import { format } from "date-fns";

import {
  WP_URL,
  WP_OG_URL,
  WC_API_USERNAME,
  WC_API_PASSWORD,
  GCP_CAMPAIGNS_FILE_URL,
  DIRECTUS_URL,
} from "../config/wp_api";
import CAMPAIGN from "utils/constants/campaign";
import { date_formats } from "utils/constants/global";
import _ from "lodash"

const apiId = "network - campaign :-";

const parseCampaign = (campaign) => {
  //fetch
  campaign.acf = campaign.acf || {};
  campaign.images = campaign.images || [];
  const image = campaign.images.length > 0 ? campaign.images[0] : { src: "" };

  const flattenMetaDataArray = (arr) => {
    let flattenedObj = {};
    for (let obj of arr) {
      flattenedObj[obj.key] = obj.value;
    }

    return flattenedObj;
  };

  const metaData = flattenMetaDataArray(campaign.meta_data);

  const lobCategory = campaign.categories[0].id;

  return {
    id: campaign.id,
    name: campaign.name,
    content: campaign.acf.short_description,
    link: campaign.acf.link,
    img: image.src,
    embed: campaign.acf.embed,
    more: campaign.description,
    status:
      campaign.status === CAMPAIGN.api_status.active
        ? CAMPAIGN.display_status.active
        : CAMPAIGN.display_status.inactive,
    lastUpdated: format(
      new Date(campaign.date_modified_gmt),
      date_formats.for_display
    ),
    startDate: metaData[CAMPAIGN.wp_acf_keys.start_date],
    endDate: metaData[CAMPAIGN.wp_acf_keys.end_date],
    newUserCommissionType:
      metaData[CAMPAIGN.wp_acf_keys.new_user_commission_type],
    newUserCommissionShare:
      metaData[CAMPAIGN.wp_acf_keys.new_user_commission_share],
    existingUserCommissionType:
      metaData[CAMPAIGN.wp_acf_keys.existing_user_commission_type],
    existingUserCommissionShare:
      metaData[CAMPAIGN.wp_acf_keys.existing_user_commission_share],
    countryType: metaData[CAMPAIGN.wp_acf_keys.country_type],
    campaignLob: lobCategory,
    Lob: campaign.acf.lob,
  };
};

const formatCampaign = (obj) => {
  //sending part
  const metaData = [
    {
      key: CAMPAIGN.wp_acf_keys.new_user_commission_type,
      value: obj.newUserCommissionType,
    },
    {
      key: CAMPAIGN.wp_acf_keys.new_user_commission_share,
      value: obj.newUserCommissionShare,
    },
    {
      key: CAMPAIGN.wp_acf_keys.existing_user_commission_type,
      value: obj.existingUserCommissionType,
    },
    {
      key: CAMPAIGN.wp_acf_keys.existing_user_commission_share,
      value: obj.existingUserCommissionShare,
    },
    {
      key: CAMPAIGN.wp_acf_keys.user_type,
      value: obj.userType,
    },
    {
      key: CAMPAIGN.wp_acf_keys.country_type,
      value: obj.countryType,
    },
    {
      key: CAMPAIGN.wp_acf_keys.short_description,
      value: obj.shortDescription,
    },
    {
      key: CAMPAIGN.wp_acf_keys.link,
      value: obj.campaignLink,
    },
    {
      key: CAMPAIGN.wp_acf_keys.embed,
      value: obj.embed,
    },
    {
      key: CAMPAIGN.wp_acf_keys.start_date,
      value: obj.startDate,
    },
    {
      key: CAMPAIGN.wp_acf_keys.end_date,
      value: obj.endDate,
    },
  ];

  const images = obj.imageUrl
    ? [
        {
          src: obj.imageUrl,
        },
      ]
    : [];

  // const lastUpdatedDate = obj.lastUpdated
  //   ? format(new Date(obj.lastUpdated), date_formats.for_api)
  //   : format(new Date(), date_formats.for_api);

  return {
    name: obj.title,
    description: obj.fullDescription,
    status: obj.isActive
      ? CAMPAIGN.api_status.active
      : CAMPAIGN.api_status.inactive,
    // acf: {
    //   short_description: obj.shortDescription,
    //   link: obj.campaignLink,
    //   embed: obj.embed,
    // },
    // date_modified_gmt: lastUpdatedDate,
    meta_data: metaData,
    categories: [
      {
        id: obj.campaignLob,
      },
    ],
    images: images,
  };
};

const axiosInstance = axios.create({
  baseURL: `${WP_URL}/wp-json/wc/v3`,
  auth: {
    username: WC_API_USERNAME,
    password: WC_API_PASSWORD,
  },
  responseType: "json",
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error(apiId, "Server response:", error.response.status);
      console.error(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(apiId, "No response from server");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(apiId, "Invalid request", error.message);
    }
    return Promise.reject(error);
  }
);

const getAll = async (params, axiosOptions = {}) => {
  return axiosInstance
    .get("/products", {
      params,
      ...axiosOptions,
    })
    .then((res) => {
      const response = [];

      for (let campaign of res) {
        response.push(parseCampaign(campaign));
      }

      return response;
    });
};

const getSingle = async (id) => {
  return axiosInstance.get(`/products/${id}`).then((res) => {
    return parseCampaign(res);
  });
};

const getAllActive = async () => {
  return getAll({ status: CAMPAIGN.api_status.active });
};

const getAllActiveFromBucket = async () => {
  return axios.get(GCP_CAMPAIGNS_FILE_URL);
};

const DIRECTUS_READ_TOKEN = "YwwmPQnpm9kgU4u9S--QFS6-yOzZvfAa";

const getAllActiveFromDirectus = async (isCampaignsViewer=false, lob, country) => {
  const filters = { status: { _in: ["published"] } };
  if(typeof(lob) === "number"){
    filters.campaign_lob = { _eq: lob };
  }
  if(typeof(country) === "string"){
    filters.country = { _eq: country };
  }
  if (isCampaignsViewer) {
    filters.campaign_type = { _eq: "eap" };
  } else {
    filters.campaign_type = { _eq: "aap" };
  }
  return axios.get(
    `${DIRECTUS_URL}/items/campaigns?filter=${JSON.stringify(
      filters
    )}&meta[]=filter_count&meta[]=total_count&sort[]=-priority&sort=end_time`
  );
};

const getAllCountriesFromDirectus = async () => {
  return axios.get(`${DIRECTUS_URL}/fields/campaigns/country`, {
    headers: { Authorization: `Bearer ${DIRECTUS_READ_TOKEN}` },
  });
};

const getUtmCampaignTypesFromDirectus = async () => {
  return axios.get(`${DIRECTUS_URL}/items/utm_campaign_types`, {
    headers: { Authorization: `Bearer ${DIRECTUS_READ_TOKEN}` },
  }).then(resp => {
    return _.get(resp, "data.data")
  }).catch(err => err);
};

const saveCampaignDetails = async (body, dataModel="utm_campaigns") => {
  return axios.post(`${DIRECTUS_URL}/items/${dataModel}`, body, {
    headers: { Authorization: `Bearer ${DIRECTUS_READ_TOKEN}` },
  }).then(resp => {
    return _.get(resp, "data.data")
  }).catch(err => err);
};

const getAdvertisersForUtmCampaignTypesFromDirectus = async (campaignTypeId="") => {
  return axios.get(`${DIRECTUS_URL}/items/campaign_advertisers?filter[_and][0][campaign_type][id][_eq]=${campaignTypeId}`, {
    headers: { Authorization: `Bearer ${DIRECTUS_READ_TOKEN}` },
  }).then(resp => {
    return _.get(resp, "data.data")
  }).catch(err => err);
};

const allFieldOptions = `query AllFieldOptions {
  utm_medium(limit: -1){
      id
      name
      code
      utm_type{
          id
          utm_campaign_types_id{
              name
              code
          }
      }
  }
  utm_source(limit: -1){
      id
      code
      name
      utm_type{
          id
          utm_campaign_types_id{
              name
              code
          }
      }
  }
  channel_codes(limit: -1){
      id
      code
      name
      campaign_type{
          code
          name
      }
  }
  campaign_advertisers(limit: -1){
      id
      code
      name
  }
  utm_campaign_types(limit: -1){
      id
      code
      name
      order
      type
  }
  utm_campaign_strategy_type(limit: -1){
      id
      code
      name
  }
  utm_campaign_categories(limit: -1){
      id
      code
      name
  }
  utm_campaign_display_type(limit: -1){
      id
      code
      name
      group
  }
  utm_campaign_hub(limit: -1){
      id
      code
      name
  }
}`;

const fieldOptionsByCampaignTypeId = `
query FieldOptionsByCampaignTypeId($campaignTypeId: GraphQLStringOrFloat) {
	campaign_advertisers(limit: -1 ) {
		id
    name
    code
	}
  channel_codes(filter: { campaign_type: {id: { _eq: $campaignTypeId }}}, limit: -1 ) {
		id
    name
    code
    campaign_type {
        id
        name
    }
	}
  utm_campaign_categories(limit: -1){
    name
    code
  }
  utm_campaign_strategy_type(limit: -1){
    name
    code
  }
  countries(limit: -1){
      name
      code
  }
  languages(limit: -1){
      code
      name
  }
  utm_campaign_display_type(limit: -1){
      code
      name
      group
  }
  utm_campaign_hub(limit: -1){
      code
      name
  }
  utm_lobs(limit: -1){
    code
    name
}

}
`;

const fieldOptionsForUtmForm = `
query FieldOptionsForUtmForm($utmTypeId: GraphQLStringOrFloat) {
  utm_medium(filter: {utm_type: {utm_campaign_types_id: {id: {_eq: $utmTypeId}}}}, limit: -1){
      name
      code
  }
  utm_source(filter: {utm_type: {utm_campaign_types_id: {id: {_eq: $utmTypeId}}}}, limit: -1){
      code
      name
  }
}
`;

const campaignsByUser = `
query CampaignsByUser($email: String) {
	utm_campaigns(filter: {_and: [{user: {_eq: $email}}, {_or: [{endDate: { _gte: "NOW()" }}, {endDate: {_null: true}}]}]}, limit: -1) {
		id
        name
        user
        startDate
        endDate
	}
}
`;

const utmNamesByUser = `
query CampaignsByUser($email: String) {
	utm_names(filter: {user: { _eq: $email }}, limit: -1) {
		id
    name
    user
    shortUrl
	}
}
`;

const utmUsersByEmail = `
query UtmUsers($email: String) {
	utm_users(filter: {email: { _eq: $email }}, limit: -1) {
		id
        email
	}
}
`;

const utmCampaignViewersByEmail = `
query CampaignViewers($email: String) {
	eap_users(filter: {email: { _eq: $email }}, limit: -1) {
		id
    email
	}
}
`;

const eaPgaesQuery = `
query EAP_Pages{
	eap_pages {
        announcements
        terms_and_condition
        commission_rates
	}
}
`;

const getCampaignsByUser = async (email="", isUtm=false) => {
  return axios.post(`${DIRECTUS_URL}/graphql`, {query: isUtm ? utmNamesByUser : campaignsByUser, variables: {email}}, {
    headers: { Authorization: `Bearer ${DIRECTUS_READ_TOKEN}` },
  }).then(resp => {
    return _.get(resp, "data.data")
  }).catch(err => err);
};

const getUtmUserByEmail = async (email="") => {
  return axios.post(`${DIRECTUS_URL}/graphql`, {query: utmUsersByEmail, variables: {email}}, {
    headers: { Authorization: `Bearer ${DIRECTUS_READ_TOKEN}` },
  }).then(resp => {
    return _.get(resp, "data.data")
  }).catch(err => err);
};

const getCampaignViewerByEmail = async (email="", cb=()=>{}) => {
  return axios.post(`${DIRECTUS_URL}/graphql`, {query: utmCampaignViewersByEmail, variables: {email}}, {
    headers: { Authorization: `Bearer ${DIRECTUS_READ_TOKEN}` },
  }).then(resp => {
    if(_.isFunction(cb)){
      cb(_.get(resp, "data.data"))
    }
    return _.get(resp, "data.data");
  }).catch(err => {
    if(_.isFunction(cb)){
      cb(null)
    }
    return err;
  });
};

const getEaPages = async (email="") => {
  return axios.post(`${DIRECTUS_URL}/graphql`, {query: eaPgaesQuery}, {
    headers: { Authorization: `Bearer ${DIRECTUS_READ_TOKEN}` },
  }).then(resp => {
    return _.get(resp, "data.data")
  }).catch(err => err);
};

const getFieldOptionsForUtmCampaignTypesFromDirectus = async (campaignTypeId="") => {
  return axios.post(`${DIRECTUS_URL}/graphql`, {query: fieldOptionsByCampaignTypeId, variables: {campaignTypeId}}, {
    headers: { Authorization: `Bearer ${DIRECTUS_READ_TOKEN}` },
  }).then(resp => {
    return _.get(resp, "data.data")
  }).catch(err => err);
};

// allFieldOptions
const getAllFieldOptionsFromDirectus = async () => {
  return axios.post(`${DIRECTUS_URL}/graphql`, {query: allFieldOptions}, {
    headers: { Authorization: `Bearer ${DIRECTUS_READ_TOKEN}` },
  }).then(resp => {
    return _.get(resp, "data.data")
  }).catch(err => err);
};

const getFieldOptionsForUtmFormFromDirectus = async (utmTypeId="") => {
  return axios.post(`${DIRECTUS_URL}/graphql`, {query: fieldOptionsForUtmForm, variables: {utmTypeId}}, {
    headers: { Authorization: `Bearer ${DIRECTUS_READ_TOKEN}` },
  }).then(resp => {
    return _.get(resp, "data.data")
  }).catch(err => err);
};

const shortenerAxios = axios.create({
  baseURL: "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBd6EHh8VLQR41vRL6IneeVn5g4tyEtlEc",
  headers: { "Content-Type": "application/json" },
  method: "POST"
});

const urlShortener = async (url, callback, errorCallback) => {
  return shortenerAxios.post("", {
    "longDynamicLink": `https://airasia.page.link/?link=${encodeURIComponent(url)}&apn=com.airasia.android&ibi=com.airasia.ios`,
    "suffix": {
      "option": "SHORT"
    }
  }).then(resp => {
    callback(resp.data.shortLink.replace("airasia.page.link", "air.asia"))
  }).catch(err => {
    errorCallback("", true);
  })
}

const getAssetsForCampaignId = async (campaignID) => {
  return axios.get(
    `https://rad.airasiatech.com/items/campaigns_files?fields[]=directus_files_id.id&fields[]=directus_files_id.type&fields[]=directus_files_id.title&fields[]=id&filter=%7B%22campaigns_id%22:%22${campaignID}%22%7D&page=1&limit=15&fields=directus_files_id`
  );
};

const getAssetDataById = (id) => {
  return axios.get(
    `https://rad.airasiatech.com/files/${id}?fields[]=id&fields[]=title&fields[]=width&fields[]=height&fields[]=filesize&fields[]=type&fields[]=filename_download&access_token=ZfRyCGaJQvVQdNKebtKvLEXSZmYovZEQ&width=200&height=200`
  );
};

const create = async (obj, axiosOptions = {}) => {
  return axiosInstance.post("/products", formatCampaign(obj), {
    ...axiosOptions,
  });
};

const update = async (obj, axiosOptions = {}) => {
  return axiosInstance.post(`/products/${obj.id}`, formatCampaign(obj), {
    ...axiosOptions,
  });
};

const deleteSingle = async (id, axiosOptions = {}) => {
  return axiosInstance.delete(`/products/${id}`, {
    ...axiosOptions,
  });
};

export default {
  getAll,
  getSingle,
  getAllActive,
  urlShortener,
  getAllActiveFromBucket,
  getAllActiveFromDirectus,
  getAllCountriesFromDirectus,
  getUtmCampaignTypesFromDirectus,
  getAdvertisersForUtmCampaignTypesFromDirectus,
  getFieldOptionsForUtmCampaignTypesFromDirectus,
  getFieldOptionsForUtmFormFromDirectus,
  getAllFieldOptionsFromDirectus,
  saveCampaignDetails,
  getCampaignsByUser,
  getUtmUserByEmail,
  getCampaignViewerByEmail,
  getEaPages,
  getAssetsForCampaignId,
  getAssetDataById,
  create,
  update,
  deleteSingle,
  WP_OG_URL,
};
