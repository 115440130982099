import React, {useContext} from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import _ from "lodash";
import {
  SiInstagram,
  SiFacebook,
  SiTwitter,
  SiYoutube,
  SiTiktok,
} from "react-icons/si";
import FAQ from "@airasia/phoenix-widgets/widgets/FAQ";
import "./GrowthAmbassador.css";
import SSOLogin from "components/SsoLogin";
import { UserContext } from "UserContext";
import { Typography } from "@material-ui/core";
const importAll = (require) =>
  require.keys().reduce((acc, next) => {
    acc[next.replace("./", "")] = require(next);
    return acc;
  }, {});
const Images = importAll(
  require.context("assets/img/landing", false, /\.(png|jpe?g|svg)$/)
);

const Page = styled.div`
  background-color: #ffffff;
  width: 100%;
`;
const Center = `
    place-content: center;
    place-items: center;
`;
const BackgroundSetting = `
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
`;
const FlexColumnSetting = `
    display: flex;
    flex-direction: column;
`;
const FlexRowSetting = `
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  place-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  height: 64px;
  padding: 0 48px;
  position: -webkit-stick;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  @media (max-width: 1024px) {
    position: fixed;
  }
  @media (max-width: 800px) {
    padding: 0 24px;
  }
  @media (max-width: 400px) {
    padding: 0px 12px;
  }
`;
const HeaderFirstColumn = styled.div`
  ${FlexRowSetting}
  place-items: center;
  column-gap: 24px;
  @media (max-width: 480px) {
    column-gap: 12px;
  }
`;
const HeaderSecondColumn = styled.div`
  ${FlexRowSetting}
  place-items: center;
  gap: 24px;
`;
const HeaderLogo = styled.img`
  height: 64px;
  background-color: #EC3024;
  @media (max-width: 400px) {
    height: 40px;
  }
`;
const Banner = styled.div`
  background-image: url(${Images["aap_hero_banner.png"]});
  height: 25vw;
  background-position: center;
  background-size: contain;
  // right: 0;
  @media (max-width: 1024px) {
    margin-top: 64px;
    background-image: url(${Images["aap_hero_banner.png"]});
    width: 100%;
    padding: 0px;
    height: 35vw;
    background-repeat: no-repeat;
    border: 1px solid red;
    background-size: 140%;
    background-position-x: 35%;
  }
  @media (max-width: 800px) {
    background-image: url(${Images["aap_hero_banner.png"]});
    width: 100%;
    padding: 0px;
    height: 45vw;
    background-repeat: no-repeat;
    border: 1px solid red;
    background-size: 180%;
    background-position-x: 35%;
  }
  @media (max-width: 480px) {
    background-image: url(${Images["aap_hero_banner.png"]});
    width: 100%;
    padding: 0px;
    height: 45vw;
    background-repeat: no-repeat;
    border: 1px solid red;
    background-size: 180%;
    background-position-x: 35%;
  }
`;
const FirstRow = styled.div`
  ${FlexColumnSetting}
  ${Center}
    text-align: center;
  padding: 48px 24px;
  @media (max-width: 480px) {
    padding: 24px;
  }
`;
const FirstRowColumn = styled.div`
  ${FlexRowSetting}
  ${Center}
    gap: 48px;
  @media (max-width: 480px) {
    gap: 24px;
  }
  @media (min-width: 1024px) {
    text-align: left;
    flex-wrap: nowrap;
  }
`;
const SecondRow = styled.div`
  background-color: #f8f8f8;
  padding: 48px 24px;
  @media (max-width: 480px) {
    padding: 24px;
  }
`;
const SecondRowContainer = styled.div`
  ${FlexRowSetting}
  ${Center}
    column-gap: 120px;
  row-gap: 40px;
`;
const SecondRowSecondColumn = styled.div`
  ${FlexColumnSetting}
`;
const ThirdRow = styled.div`
    ${FlexColumnSetting}
    background-image: url(${Images["background-third-row.png"]});
    padding: 48px 20%;
    text-align: center;
    color: #FFFFFF;
    @media (max-width: 1024px) {
        padding: 48px 24px 24px;
    }
`;
const FourthRow = styled.div`
  ${FlexRowSetting}
  ${Center}
    column-gap: 90px;
  padding: 54px 24px;
  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
  @media (max-width: 480px) {
    padding: 24px 24px;
  }
`;
const FourthRowSecondColumn = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 60px;
  row-gap: 40px;
  margin-bottom: 40px;
  max-width: 800px;
  @media (max-width: 480px) {
    gap: 40px;
  }
`;
const FifthRow = styled.div`
  ${FlexColumnSetting}
`;
const FifthRowBackground = styled.div`
  background-image: url(${Images["background-fifth-row.png"]});
  ${BackgroundSetting}
  height: 260px;
  width: -webkit-fill-available;
  padding: 16px 146px;
  text-align: left;
  color: #ffffff;
  position: absolute;
  z-index: 2;
  @media (max-width: 480px) {
    padding: 16px 24px;
  }
`;
const TestimonialContainer = styled.div`
  ${Center}
  ${FlexRowSetting}
    gap: 24px;
  margin: 100px 24px 0;
  place-self: center;
  z-index: 3;
`;
const SixthRow = styled.div`
  display: block;
  margin: 4% auto;
  width: 80%;
`;

const Bottom = styled.div`
  display: flex;
  height: 398px;
  background: #cb4231;
  place-items: center;
`;
const FirstRowImage = styled.img`
  width: 500px;
  margin: 0;
  @media (max-width: 480px) {
    width: 80%;
  }
`;
const SecondRowImage = styled.img`
  width: 414px;
  margin-top: 30px;
  @media (max-width: 480px) {
    width: 80%;
  }
`;
const ThirdRowImage = styled.img`
  width: 312;
  margin: 0;
  @media (max-width: 480px) {
    width: 90%;
  }
`;
const WhiteNormalFont = `
    font-family: "Dm Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    color: #FFFFFF;
`;
const BlackNormalFont = `
    font-family: "Dm Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    color: #000000;
`;
const BlackNormalFont2 = `
    font-family: "Dm Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    color: #000000;
`;
const Menu = styled.a`
  ${BlackNormalFont}
  line-height: 100%;
  font-size: 16px;
  background: none;
  border: none;
  cursor: pointer;
  &:hover {
    color: #dc3224;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const BannerHeadline = styled.div`
  ${WhiteNormalFont}
  line-height: 140%;
  font-size: 20px;
  @media (max-width: 800px) {
    max-width: 60%;
    font-size: 20px;
    margin-bottom: 12px;
  }
  @media (max-width: 480px) {
    max-width: 80%;
    font-size: 16px;
    margin-bottom: 8px;
  }
`;
const BannerHighlight = styled.img`
  height: 48px;
  margin: 0 -8px;
  @media (max-width: 480px) {
    height: auto;
    width: 100%;
    margin: 4px 0 0 0;
  }
`;
const RedButton = `
    width: 180px;
    height: 48px;
    background: #DC3224;
    mix-blend-mode: normal;
    border: 1px solid #DC3224;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    ${WhiteNormalFont}
    font-size: 16px;
    line-height: 150%;
    margin: 0;
    @media (max-width: 480px) {
        height: 40px;
        font-size: 14px;
    }
`;
const HeaderButton = styled.button`
  ${RedButton}
  @media (max-width: 480px) {
    width: fit-content;
  }
`;
const BannerButton = styled.button`
  ${RedButton}
  transform: rotate(352deg);
  background: white;
  color: #DC3224;
  position: relative;
  top: 74%;
  left: 20%;
  @media (max-width: 600px) {
    height: 36px;
    font-size: 18px;
    background: -webkit-linear-gradient(#fff, #fcfcfc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-width: 0px;
  }
  @media (max-width: 1024px) {
    min-width: 20vw;
    width: 300px;
    max-width: 40vw;
  }
  @media (min-width: 1024px) {
    width: 18vw;
    top: 75%;
    left: 29%;
  }
`;
const CTAButton = styled.button`
  ${RedButton}
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const FirstRowTitle = styled.h3`
  ${BlackNormalFont}
  font-size: 20px;
  line-height: 140%;
  margin: 0;
  @media (max-width: 480px) {
    font-size: 18px;
  }
`;
const FirstRowText = styled.div`
  ${BlackNormalFont}
  line-height: 150%;
  margin: 0;
  @media (min-width: 1024px) {
    max-width: 50%;
  }
`;
const ListWrapper = styled.div`
  position: relative;
`;
const ListItemWrapper = styled.div`
  margin-top: 10px;
  position: relative;
`;
const ListBullet = styled.div`
  float: left;
  margin-right: 20px;
  background: #d9dbe0;
  opacity: 0.4;
  height: 8px;
  width: 8px;
  line-height: 21px;
  border-radius: 100px;
  padding: 7px;
  z-index: 3;
`;
const ListBulletInside = styled.div`
  float: left;
  position: absolute;
  margin: 7px 7px;
  background: #d9dbe0;
  opacity: 1;
  height: 8px;
  width: 8px;
  border-radius: 100px;
  line-height: 21px;
  z-index: 4;
`;
const ListItem = styled.div`
  display: table-row;
  vertical-align: middle;
`;
const ListTitle = styled.h2`
  ${BlackNormalFont2}
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 21px;
`;
const ListText = styled.div`
  ${BlackNormalFont2}
  font-size: 14px;
  line-height: 21px;
`;
const GreyLine = styled.hr`
  background: #d9dbe0;
  width: 1px;
  height: 160px;
  position: absolute;
  border: none;
  margin-left: 10.5px;
  @media (max-width: 400px) {
    height: calc(200% + 20px);
  }
`;
const ThirdRowTitle = styled.h3`
  ${WhiteNormalFont}
  line-height: 140%;
  font-size: 20px;
  margin: 0 0 48px;
`;
const BenefitTitle = styled.div`
  ${WhiteNormalFont}
  line-height: 150%;
  font-size: 16px;
  margin: 8px 0 10px;
`;
const BenefitContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 90px;
  row-gap: 48px;
  justify-content: center;
`;
const BenefitCard = styled.div`
  width: 312px;
  @media (max-width: 600px) {
    margin-bottom: 20px;
  }
`;
const BenefitText = styled.div`
  font-family: "Dm Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #ffffff;
  line-height: 150%;
  font-size: 14px;
  text-align: center;
`;
const FourthRowTitle = styled.h2`
  font-style: normal;
  font-weight: 500;
  color: #000000;
  font-size: 24px;
  line-height: 36px;
`;
const HowToContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  place-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 20px;
  }
`;

const HowToCircle = styled.div`
  float: left;
  background: #ededf6;
  height: 40px;
  width: 40px;
  border-radius: 100px;
  padding: 20px;
  @media (max-width: 480px) {
    width: 30px;
    height: 30px;
    padding: 15px;
  }
  @media screen and (max-width: 768px) {
    margin-right: 5%;
  }
`;
const HowToImage = styled.img`
  width: 40px;
  height: 40px;
  @media (max-width: 600px) {
    width: 30px;
    height: 30px;
  }
`;
const HowToText = styled.div`
  max-width: 227px;
  ${BlackNormalFont2}
  line-height: 150%;
  font-size: 16px;
`;
const FifthRowTitle = styled.h2`
  ${WhiteNormalFont}
  font-size: 24px;
  line-height: 36px;
`;
const TestimonialBox = styled.div`
  width: 240px;
  height: 300px;
  background: #f8f8f8;
  border-radius: 4px;
  padding: 24px;
  margin: 10px;
`;
const TestiTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #212124;
  margin-bottom: 8px;
`;
const Testi = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #4c4c50;
  margin-bottom: 12px;
`;
const TestiUser = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #212124;
  margin: 0;
`;
const TestiLocation = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #75767a;
`;
const SixthRowTitle = styled.h2`
  font-style: normal;
  font-weight: 500;
  color: #000000;
  font-size: 24px;
  line-height: 36px;
  @media (max-width: 600px) {
    margin-top: 48px;
  }
`;
const BottomText = styled.h1`
  ${WhiteNormalFont}
  line-height: 130%;
  font-size: 32px;
  max-width: 448px;
  z-index: 3;
  margin-left: 170px;
  @media (max-width: 600px) {
    font-size: 20px;
    margin-left: 24px;
  }
`;
const BottomLogo = styled.img`
  position: absolute;
  right: 0;
  z-index: 2;
`;

const VideoLoader = styled.div`
  width: 50%;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
const GrowthAmbassador = () => {
  const { user = {} } = useContext(UserContext);

  const [prevScrollPos, setPrevScrollPos] = React.useState(0);
  const [visible, setVisible] = React.useState(true);
  const [activeMenu, setActiveMenu] = React.useState("");
  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setVisible(
      (prevScrollPos > currentScrollPos &&
        prevScrollPos - currentScrollPos > 70) ||
        currentScrollPos < 150
    );
    setPrevScrollPos(currentScrollPos);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  const scrollToDiv = (id) => {
    const elem = document.getElementById(id);
    if (elem) {
      elem.scrollIntoView();
      setActiveMenu(id);
    }
  };

  return (
    <Page>
      <Header
        style={{ boxShadow: visible ? "none" : "rgb(0 0 0 / 16%) 0px 1px 4px" }}
      >
        <HeaderFirstColumn>
          <HeaderLogo src={Images["aap_logo_white_in_red.svg"]} alt="Logo" />
        </HeaderFirstColumn>
        <HeaderSecondColumn>
          <Menu
            onClick={(e) => {
              scrollToDiv("SecondRow");
            }}
            style={
              activeMenu === "SecondRow"
                ? { textDecoration: "underline" }
                : { textDecoration: "none" }
            }
          >
            How to Join
          </Menu>
          <Menu
            onClick={(e) => {
              scrollToDiv("ThirdRow");
            }}
            style={
              activeMenu === "ThirdRow"
                ? { textDecoration: "underline" }
                : { textDecoration: "none" }
            }
          >
            Benefits
          </Menu>
          <Menu
            onClick={(e) => {
              scrollToDiv("SixthRow");
            }}
            style={
              activeMenu === "SixthRow"
                ? { textDecoration: "underline", fontColor: "#9c27b0" }
                : { textDecoration: "none" }
            }
          >
            FAQs
          </Menu>
          <Menu
            onClick={(e) => {
              scrollToDiv("Bottom");
            }}
            style={
              activeMenu === "Bottom"
                ? { textDecoration: "underline" }
                : { textDecoration: "none" }
            }
          >
            Support
          </Menu>
          {_.has(user, "userInfo.username") 
              ? (_.get(user, "isAllstar") || _.get(user, "isCampaignsViewer"))
              ? (
                <Link to="/admin/dashboard">
                  <HeaderButton className="join-us">Access Dashboard</HeaderButton>
                </Link>
              )
              : <Typography style={{color: "red"}}>Only Allstars are eligible</Typography> 
              : <Link to="/login">
              <HeaderButton className="join-us">Join Us</HeaderButton>
            </Link>
            }
          <SSOLogin />
        </HeaderSecondColumn>
      </Header>
      <Banner>
        {/* <BannerHeadline>
          Introducing the
          <br />
          <BannerHighlight
            src={
              window.innerWidth <= 480
                ? Images["allstar_ambassador.png"]
                : Images["allstar_ambassador.png"]
            }
          />
        </BannerHeadline>
        <Link to="/login">
          <BannerButton>Join us</BannerButton>
        </Link> */}
        {/* <Link to="/login">
          <BannerButton>Join Us</BannerButton>
        </Link> */}
      </Banner>
      <FirstRow id="FirstRow">
        <FirstRowTitle>
          Monetize your Content and Earn Commission with airasia
        </FirstRowTitle>
        <FirstRowColumn>
          {/* <FirstRowImage src={Images['influencer-icon.png']} alt="are you influencer"/> */}
          <VideoLoader>
            <video autoplay muted loop controls id="myVideo" width="100%">
              <source
                src="https://assets.contentstack.io/v3/assets/blt81780e535e9d8b2b/blt7a6b3904d284d7e5/60d906abacc3ac4726560216/aasupercomp.mp4"
                type="video/mp4"
              />
              <img
                src="/ambassador/static/media/influencer-icon.b97de40a.png"
                alt="are you influencer"
                class="sc-iJKOzS hhycBg"
              />
            </video>
          </VideoLoader>
          <FirstRowText style={{ fontSize: "16px" }}>
            The Allstars Ambassador Programme is for all Allstars who can promote airasia.
            <br />
            <br />
            All you need is a social media account on <SiInstagram /> Instagram,{" "}
            <SiFacebook /> Facebook, <SiTwitter /> Twitter, <SiYoutube />{" "}
            Youtube, <SiTiktok /> Tiktok or any other social media.
          </FirstRowText>
        </FirstRowColumn>
      </FirstRow>
      <SecondRow id="SecondRow">
        <SecondRowContainer>
          <div style={{ width: "414px", textAlign: "center" }}>
            <FirstRowTitle>
              Don’t know how to start?
              <br />
              No worries, we are here to help you!
            </FirstRowTitle>
            <SecondRowImage
              className="secondRowImage"
              alt="we are here to help"
              src={Images["help-icon.png"]}
            />
          </div>
          <SecondRowSecondColumn>
            <ListTitle>How to Join in 3 easy steps?</ListTitle>
            <ListWrapper>
              <ListItemWrapper>
                <GreyLine />
                <ListBullet />
                <ListBulletInside />
                <ListItem>
                  <ListText>Signup for the Allstars Ambassador Programme</ListText>
                  <br />
                  <br />
                </ListItem>
              </ListItemWrapper>
              <ListItemWrapper>
                <ListBullet />
                <ListBulletInside />
                <ListItem>
                  <ListText>Login to the platform</ListText>
                  <br />
                  <br />
                </ListItem>
              </ListItemWrapper>
              <ListItemWrapper>
                <ListBullet />
                <ListBulletInside />
                <ListItem>
                  <ListText>
                    Use your unique link to promote airasia LOB offers on social
                    media and start earning commission
                  </ListText>
                </ListItem>
              </ListItemWrapper>
            </ListWrapper>
            <Link to="/login">
              <CTAButton style={{ marginTop: "28px" }}>Join us</CTAButton>
            </Link>
          </SecondRowSecondColumn>
        </SecondRowContainer>
      </SecondRow>
      <ThirdRow id="ThirdRow">
        <ThirdRowTitle>
          Why should you join the airasia Allstar Ambassador Programme?
        </ThirdRowTitle>
        <BenefitContainer>
          <BenefitCard>
            <ThirdRowImage alt="commission image" src={Images["join-1.png"]} />
            <BenefitTitle> Earn Extra Commission</BenefitTitle>
            <BenefitText>
              Get up to 15% commission for every successful purchase made via your unique link.
            </BenefitText>
          </BenefitCard>
          <BenefitCard>
            <ThirdRowImage alt="commission image" src={Images["join-2.png"]} />
            <BenefitTitle>Brand Association with airasia</BenefitTitle>
            <BenefitText>
              Be one of the first to receive exclusive promotional news and
              offers from airasia.
            </BenefitText>
          </BenefitCard>
          <BenefitCard>
            <ThirdRowImage alt="tracking image" src={Images["join-3.png"]} />
            <BenefitTitle>Track sales easily</BenefitTitle>
            <BenefitText>
              Keep track of your sales and performance with-in the platform.
            </BenefitText>
          </BenefitCard>
          <BenefitCard>
            <ThirdRowImage alt="payment image" src={Images["join-4.png"]} />
            <BenefitTitle>Faster payment</BenefitTitle>
            <BenefitText>
              Receive your approved commission on the 15th of every month.
            </BenefitText>
          </BenefitCard>
        </BenefitContainer>
      </ThirdRow>
      <FourthRow id="FourthRow">
        <FourthRowTitle>How to Promote and Earn?</FourthRowTitle>
        <div>
          <FourthRowSecondColumn>
            <HowToContainer>
              <HowToCircle>
                <HowToImage
                  alt="megaphone icone"
                  src={Images["icon-megaphone.png"]}
                />
              </HowToCircle>
              <HowToText>
                Generate your unique link and promote airasia to your audiences
                on social media or any other channels.
              </HowToText>
            </HowToContainer>
            <HowToContainer>
              <HowToCircle>
                <HowToImage alt="click icon" src={Images["icon-click.png"]} />
              </HowToCircle>
              <HowToText>
                When a User clicks on your unique link, they will be redirected
                to airasia.
              </HowToText>
            </HowToContainer>
            {/* <HowToContainer>
              <HowToCircle>
                <HowToImage
                  alt="shopping bag icon"
                  src={Images["icon-shopping-bag.png"]}
                />
              </HowToCircle>
              <HowToText>
                Any purchase/order made through your link is approved - unless
                returned or cancelled.
              </HowToText>
            </HowToContainer> */}
            <HowToContainer>
              <HowToCircle>
                <HowToImage
                  alt="percentage icon"
                  src={Images["icon-percentage.png"]}
                />
              </HowToCircle>
              <HowToText>
                You will receive a commission on every successful transaction
                from your referral link.
              </HowToText>
            </HowToContainer>
          </FourthRowSecondColumn>
          <Link to="/login">
            <CTAButton>Join us</CTAButton>
          </Link>
        </div>
      </FourthRow>
      <FifthRow id="FifthRow">
        <FifthRowBackground>
          <FifthRowTitle>Success Stories</FifthRowTitle>
        </FifthRowBackground>
        <TestimonialContainer>
          <TestimonialBox>
            <TestiTitle>Sunisa's Story</TestiTitle>
            <Testi>
              “Normally I always order food delivery and shared the food photo via my IG stories. As this program announced, I would like to promote airasia food delivery to my friends so I posted the food photo and ask my friends to try our airasia app which they were really surprised that airasia do the food delivery.”
            </Testi>
            <TestiUser>Sunisa Worakanpinit</TestiUser>
            <TestiLocation>Bangkok, Thailand</TestiLocation>
          </TestimonialBox>
          <TestimonialBox>
            <TestiTitle>Sayyida's Story</TestiTitle>
            <Testi>
              “I took photo of the food & screenshot of the receipt that I ordered from airasia food then posted on Instagram Stories with my unique link. Sometimes I also shared the latest offer on Whatsapp Stories. I have received a few replies from my friends that they succeed order too and suprised with discount then my friends share too on their Instagram Stories :)”
            </Testi>
            <TestiUser>Sayyida Azizah</TestiUser>
            <TestiLocation>Tangerang, Indonesia</TestiLocation>
          </TestimonialBox>
          <TestimonialBox>
            <TestiTitle>Rakesh's Story</TestiTitle>
            <Testi>
              “I have shared the latest offers with my friends in WhatsApp, Facebook & Telegram groups. As the offers are attractive we formed a group and started sharing the offers in our social profiles and groups which resulted in a lot of clicks and shares.”
            </Testi>
            <TestiUser>Rakesh</TestiUser>
            <TestiLocation>Kuala Lumpur, Malaysia</TestiLocation>
          </TestimonialBox>
        </TestimonialContainer>
      </FifthRow>
      <SixthRow id="SixthRow">
        <SixthRowTitle>Frequently asked questions (FAQs)</SixthRowTitle>
        <FAQ
          data={[
            {
              question: "How do I start?",
              answer:
                "Simply sign up by logging in with your airasia.com email address.",
            },
            {
              question: "Where will you find the promotional campaigns?",
              answer:
                "Once you have successfully joined the programme, go to your dashboard , click on the respective airasia LOB offers where you will find the discounted deals/voucher codes that you can promote.",
            },
            {
              question: "How frequent is the promotional campaigns update?",
              answer:
                "Promotional campaigns will be updated from time-to-time, and more attractive LOB campaigns will be unlocked. We encourage Allstars to check out the active campaigns frequently.",
            },
            {
              question: "How to check your earned Commission?",
              answer:
                "Login to your dashboard > Go to reports, you are able to track your clicks, orders, commissions.",
            },
          ]}
          size={{}}
          theme="airasia_CLASSIC"
          titleMarginBottom="normal"
          titleType="h3"
        />
      </SixthRow>
      <Bottom id="Bottom">
        <BottomLogo alt="bottom logo" src={Images["bottom-logo.png"]} />
        <BottomText>
          For any questions email us at{" "}
          <a style={{ color: "inherit" }} href="mailto:growth@airasia.com">
            growth@airasia.com
          </a>{" "}
        </BottomText>
      </Bottom>
    </Page>
  );
};

export default GrowthAmbassador;
