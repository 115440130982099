import React, { useState, useEffect, useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { useQuery } from "@tanstack/react-query";
import apiServices from "services/api/campaign.js";


import { DashboardRoutes, AdminDashboardRoute, CampaignViewerDashboardRoutes } from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/landing/aap_logo_white_in_red.svg";
import whiteLogo from "assets/img/aacom-white.svg"
import { auth } from "components/firebase";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import authService from "services/api/authentication";
import categoriesService from "services/api/categories";

import {
  categoryIdKey,
  lob_admin_roles,
  userRoleKey,
  userObjKey,
  allCategoryIdKey
} from "utils/constants/global";
import { airAsiaMonogramAscii } from "./constants";
import { UserContext } from "UserContext";

let ps;

const { getUtmUserByEmail, getCampaignViewerByEmail } = apiServices;

const SpecialPrivateRoute = (props) => {

  const { user = {} } = useContext(UserContext);
  const {data:userData={}, status: userDataStatus} = useQuery(["utm-user", user], ()=>getUtmUserByEmail(_.get(user, "userInfo.username", "")));
  
  if (!_.isEmpty(userData.utm_users)) {
    return <Route {...props} />;
  } else {
    return <Redirect to="/admin/dashboard" />;
  }
};

const SwitchRoutes = ({ routes }) => {

  return (
    <Switch>
      {routes.map((prop, key) => {
        if (prop.layout === "/admin") {
          if(prop.isSpecialPermissionNeeded){
            return <SpecialPrivateRoute component={prop.component} path={prop.layout + prop.path} key={key} />
          }
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}
      <Redirect from="/admin" to="/admin/dashboard" />
    </Switch>
  );
};

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {

  const { user = {} } = useContext(UserContext);
  const isCampaignsViewer = _.get(user, "isCampaignsViewer");
  const {data: campaignViewerData={}, status: campaignViewerDataStatus} = useQuery(["eap-user", user], ()=>getCampaignViewerByEmail(_.get(user, "userInfo.username", "")));
  const {data:userData={}, status: userDataStatus} = useQuery(["utm-user", user], ()=>getUtmUserByEmail(_.get(user, "userInfo.username", "")));
  // console.log("userDatauserDatauserDatauserData --- ", userData.utm_users, !_.isEmpty(userData.utm_users))
  // styles
  // console.log("%c\n" + airAsiaMonogramAscii, "color: red;");
  const history = useHistory();
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("red");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [routesArray, setRoutesArray] = React.useState(
    () => {
    if(!_.isUndefined(campaignViewerData) && !_.isEmpty(campaignViewerData.eap_users)){
      return CampaignViewerDashboardRoutes;
    }else{
      return DashboardRoutes;
    }
  });

  useEffect(()=>{
    if(!_.isUndefined(campaignViewerData) && !_.isEmpty(campaignViewerData.eap_users)){
      setRoutesArray(CampaignViewerDashboardRoutes);
    }else{
      setRoutesArray(DashboardRoutes);
    }
  }, [campaignViewerData])



  const [sideBarLinks, setSideBarLinks] = useState(
    routesArray.filter((x) => {
      if (!x.isSpecialPermissionNeeded) {
        return true;
      }
      return false;
    })
  );

  useEffect(()=>{
    setSideBarLinks(()=>{
      return routesArray.filter((x) => {
        if (!x.isSpecialPermissionNeeded) {
          return true;
        }
        else if (x.isSpecialPermissionNeeded && !_.isUndefined(userData) && !_.isEmpty(userData.utm_users)) {
          return true;
        } else {
          return false;
        }
      })
    })
  }, [userData, campaignViewerData, routesArray])

  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={sideBarLinks}
        logo={isCampaignsViewer ? whiteLogo : logo}
        image={isCampaignsViewer ? whiteLogo : image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={sideBarLinks}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              <SwitchRoutes routes={sideBarLinks} />
            </div>
          </div>
        ) : (
          <div className={classes.map}>
            <SwitchRoutes routes={sideBarLinks} />
          </div>
        )}
        {getRoute() ? <Footer /> : null}
        {/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
        /> */}
      </div>
    </div>
  );
}
