import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Login from "views/Login/login";
import _ from "lodash";

// Theme
import Theme from "./theme";

// core components
import Admin from "layouts/Admin.js";
import RTL from "layouts/RTL.js";
import GrowthAmbassador from "./views/GrowthAmbassador/GrowthAmbassador";
import AffiliatesLandingPage from "./views/AffiliatesLandingPage";
import Shortcut from "./views/shortCut";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "assets/css/material-dashboard-react.css?v=1.9.0";
import TagManager from "react-gtm-module";
import UserCtxProvider, { UserContext } from "UserContext";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Nintendo from "views/Nintendo";

const tagManagerArgs = {
  gtmId: "GTM-N9RH63J",
  dataLayerName: "dataLayer",
};

TagManager.initialize(tagManagerArgs);
const hist = createBrowserHistory();
const queryClient = new QueryClient();

const PrivateRoute = (props) => {
  const { user = {} } = useContext(UserContext);
  if (_.get(user, "userInfo.username") && (_.get(user, "isAllstar") || _.get(user, "isCampaignsViewer"))) {
    return <Route {...props} />;
  } else {
    return <Redirect to="/" />;
  }
};

const App = () => {
  return (
      <QueryClientProvider client={queryClient}>
    <UserCtxProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Theme>
            <Router history={hist} basename={"/ambassador"}>
              <Switch>
                <Route path="/login" component={Login} />
                <PrivateRoute path="/admin" component={Admin} />
                <Route path="/rtl" component={RTL} />
                <Route path="/shortcut" component={Shortcut} />
                <Route path="/nintendo" component={Nintendo} />
                <Route path="/affiliates" component={AffiliatesLandingPage} />
                <Route path="/" component={GrowthAmbassador} />
                <Redirect from="/" to="/admin/dashboard" />
              </Switch>
            </Router>
          </Theme>
        </MuiPickersUtilsProvider>
        <ReactQueryDevtools initialIsOpen={false} />
    </UserCtxProvider>
      </QueryClientProvider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
