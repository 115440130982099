import React, {useContext} from "react";
import Login from "@airasia/phoenix-widgets/widgets/Login";
import axios from "axios";
import _ from "lodash";
import { UserContext } from "UserContext";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DeviceDetector from "device-detector-js";

import { useQuery } from "@tanstack/react-query";
import apiServices from "services/api/campaign.js";

const { getCampaignViewerByEmail } = apiServices;


const deviceDetector = new DeviceDetector();



const SSOLogin = ({ onUserLoginStatus = () => {} }) => {

  const {user={}, updateUser=()=>{}} = useContext(UserContext);
  // const {data: campaignViewerData={}, status: campaignViewerDataStatus} = useQuery(["eap-user-login", _.get(user, "userInfo.username", "")], ()=>getCampaignViewerByEmail(_.get(state, "userInfo.username", "")));

  const isLargeScreen = useMediaQuery('(min-width:800px)');

  return (
    <Login
      loginLabel={"Login"}
      iconType={"AccountCircle"}
      loginMenuData={DEFAULT_DATA_LOGIN_MENU}
      tooltipdata={TOOLTIP_DATA}
      deviceType={isLargeScreen ? "desktop" : "mobile"}
      locale={"en-GB"}
      shouldShowTooltip={false}
      secretKey={"thiranthidu-sesame"}
      shouldSetCookie
      reloadOnLogin
      environment={process.env.NODE_ENV==="production" ? "production" : "staging"}
      onUserLoginStatus={(loginStatus) => {
        console.log("onUserLoginStatus -- ", loginStatus);
        onUserLoginStatus(loginStatus);
      }}
      getInfoConfigTokens={(infos) => {

        let userAgent = _.get(navigator, "userAgent", "");

        let device = deviceDetector.parse(userAgent);

        console.log("device --- ", device);

        if (!_.isEmpty(infos)) {
          if(_.get(user, "userInfo.username", "-") !== _.get(infos, "userInfo.username", "|")){
            axios.get("https://t.airasia.com/nearestairport/airport/user").then(resp => {
              axios.post("https://us-central1-airasia-gamification-stg.cloudfunctions.net/syncUserToBq", { country: _.get(resp, "data.country", ""), deviceBrand: _.get(device, "device.brand", ""), deviceType: _.get(device, "device.type", ""), ..._.get(infos, "userInfo", {}) }).catch(err => {
                console.error("Error pushing data to bq --- ", err);
              })
            }).catch(err => {
              console.error("Error getting country --- ", err);
            })
          }
        }
        getCampaignViewerByEmail(_.get(infos, "userInfo.username", ""), (resp) => {
          console.log("from response of sso login -- ", resp)
          if(_.isEmpty(_.get(resp, "eap_users"))){
            updateUser(infos);
          }else{
            updateUser({...infos, isCampaignsViewer: true});
          }
        })
        console.log("getInfoConfigTokensgetInfoConfigTokensgetInfoConfigTokensgetInfoConfigTokens --- ", infos);
      }}
    />
  );
};

export default SSOLogin;

export const TOOLTIP_DATA = [
  {
    data: [
      {
        title: "Login now to access Dashboard",
        description:
          "Log into to access Dashboard, Campaigns and much more",
        loginButtonText: "Login",
      },
    ],
  },
];

export const DEFAULT_DATA_LOGIN_MENU = [
  {
    memberData: [],
  },
  {
    navigations: [
      {
        id: "myaccount",
        icon: "account_box",
        label: "My account",
        redirectUrl: "https://www.airasia.com/member/myaccount?culture=en-GB",
        newTab: "TRUE",
      },
    ],
  },
  {
    logoutButton: [
      {
        id: "logout",
        icon: "",
        label: "Logout",
        redirectionUrl: "",
        newTab: "FALSE",
      },
    ],
  },
];
